import React from 'react';
import { Link } from 'react-router-dom';
import cookiesAccept from '../../actions/cookies.accept';
import { initStoreAndHistory } from '../../store';

const CookiesPopUp = () => {
    const onButtonClick = () => {
        const { store } = initStoreAndHistory({});
        store.dispatch(cookiesAccept());
    };

    return (
        <div className="cookies-pop-up">
            <div className="cookies-pop-up__content">
                <p>
                    На нашем сайте используются файлы cookies, которые делают
                    его более удобным для каждого пользователя. Посещая страницы
                    сайта, вы соглашаетесь с нашей{' '}
                    <Link
                        className="orange-text"
                        to="/privacy-policy"
                    >
                        Политикой Конфиденциальности
                    </Link>
                    . Подробнее ознакомиться с тем, для чего именно необходимы
                    файлы cookies, можно{' '}
                    <Link
                        className="orange-text"
                        to="/cookies-policy"
                    >
                        здесь
                    </Link>
                    .
                </p>
            </div>
            <div className="cookies-pop-up__footer">
                <button
                    className="btn cookies-pop-up__btn"
                    onClick={onButtonClick}
                >
                    ОК
                </button>
            </div>
        </div>
    );
};

export default CookiesPopUp;
